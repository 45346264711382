/**
 * Extracts the file name from a given path.
 *
 * @param path The path from which to extract the file name.
 * @returns The extracted file name.
 */

// steps:
// 1. Split the path into segments using '/' as a delimiter.
// 2. Take the last segment (usually the file name with extension).
// 3. Remove the file extension using a regular expression.
// 4. Replace hyphens (`-`) with spaces to improve readability.

export const getFileName = (path: string): string => {
  return (
    path
      .split('/')
      .pop()
      ?.replace(/\.[^/.]+$/, '')
      ?.replace(/-/g, ' ') || ''
  );
};
